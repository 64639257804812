<template>
  <el-dialog
    title="自动分配仓位"
    :close-on-click-modal="false"
    append-to-body
    :visible.sync="visible"
    class="missionPart"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="100px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <div class="tablePart">
        <el-form-item label="总仓位">{{ dataForm.spaceNum }}</el-form-item>
        <el-form-item label="剩余空仓位">
          {{ dataForm.remainingSpaceNum }}
          <i class="el-icon-tickets" v-popover:popover></i>
        </el-form-item>

        <el-form-item label="填充仓位数量" prop="usedSpaceNum">
          <el-input-number v-model="dataForm.fillSpaceNum" :precision="0" />
        </el-form-item>

        <el-form-item label="公司" prop="companyId" v-if="isGroup">
          <el-select
            v-model="dataForm.companyId"
            placeholder="公司"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="分配现售商品" class="fullList">
          <el-table
            :data="dataList"
            border
            style="width: 100%"
            @selection-change="selectionChangeHandle"
          >
            <el-table-column
              type="selection"
              header-align="center"
              align="center"
              width="50"
            />
            <el-table-column
              prop="name"
              header-align="center"
              align="center"
              label="名称"
            />
            <el-table-column
              prop="salesVolume"
              header-align="center"
              align="center"
              label="销量"
            />
            <el-table-column
              prop="price"
              header-align="center"
              align="center"
              label="原价"
            />
            <el-table-column
              prop="newPrice"
              header-align="center"
              align="center"
              label="现价"
              v-if="dataForm.companyId"
            >
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.newPrice"
                  :controls="false"
                  :precision="2"
                  :min="0"
                  size="mini"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </div>
      <el-popover placement="right" trigger="hover" ref="popover" width="430">
        <el-table :data="dataForm.shelf">
          <el-table-column
            width="100"
            prop="style"
            label="中转架规格"
          ></el-table-column>
          <el-table-column
            width="100"
            prop="space"
            label="总仓位"
          ></el-table-column>
          <el-table-column
            width="100"
            prop="usedSpace"
            label="已使用仓位"
          ></el-table-column>
          <el-table-column
            width="100"
            prop="remainingSpace"
            label="剩余仓位"
          ></el-table-column>
        </el-table>
      </el-popover>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import moment from 'moment';
import { normal, listMixin } from '@/mixins';
export default {
  mixins: [normal, listMixin],
  data() {
    return {
      visible: false,
      disabled: false,
      isGroup: false,
      dataForm: {
        id: 0,
        freezerId: '',
        periodId: '',
        orgPeriodFreezerId: '',
        orgPeriodDetailId: '',
        companyId: '',
        spaceNum: 0,
        shelf: [],
        remainingSpaceNum: 0,
        usedSpaceNum: 0,
        fillSpaceNum: 0,
        date: '',
        goodsIds: '',
      },
      companyList: [],
      dataListSelections: [],
      dataList: [],
      dataRule: {
        usedSpaceNum: [
          {
            required: true,
            message: '填充仓位数量不能为空',
            trigger: 'blur',
          },
        ],
        companyId: [
          {
            required: true,
            message: '公司不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    init(isGroup, orgPeriodFreezerId, orgPeriodDetailId, date) {
      this.dataForm.orgPeriodFreezerId = orgPeriodFreezerId;
      this.dataForm.orgPeriodDetailId = orgPeriodDetailId;
      this.dataForm.date = date;
      this.isGroup = isGroup;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        this.$http({
          url: `/tc/orgPeriodFreezer/auto-assign-init`,
          method: 'get',
          params: {
            orgPeriodFreezerId: this.dataForm.orgPeriodFreezerId,
            pickupDate: moment(this.dataForm.date).format('YYYY-MM-DD'),
          },
        }).then(({ data }) => {
          if (data && data.status === 0) {
            if (data.data) {
              this.dataForm.goodsIds = data.data.goodsIds;
              this.dataForm.remainingSpaceNum = data.data.remainingSpaceNum;
              this.dataForm.spaceNum = data.data.spaceNum;
              this.dataForm.usedSpaceNum = data.data.usedSpaceNum;
              this.dataForm.fillSpaceNum = data.data.remainingSpaceNum;
              this.dataForm.shelf = data.data.shelf;
              this.dataList = data.data.goods;
            } else {
              this.dataForm.goodsIds = '';
              this.dataForm.remainingSpaceNum = 0;
              this.dataForm.spaceNum = 0;
              this.dataForm.usedSpaceNum = 0;
              this.dataForm.fillSpaceNum = 0;
              this.dataList = [];
            }
            this.getCompany();
          } else {
            this.$message.error(data.msg);
            this.dataForm.goodsIds = '';
            this.dataForm.remainingSpaceNum = 0;
            this.dataForm.spaceNum = 0;
            this.dataForm.usedSpaceNum = 0;
            this.dataForm.fillSpaceNum = 0;
            this.dataList = [];
          }
        });
      });
    },
    getCompany() {
      this.$http({
        url: `/tc/orgPeriodCompanyFreezer/findCompany`,
        method: 'get',
        params: {
          orgPeriodFreezerId: this.dataForm.orgPeriodFreezerId,
          pickupDate: moment(this.dataForm.date).format('YYYY-MM-DD'),
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.companyList = data.data;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let ids = this.dataListSelections.map((i) => {
            return i.id;
          });
          this.$http({
            url: `/tc/orgPeriodFreezer/auto-assign`,
            method: 'post',
            data: {
              fillSpaceNum: this.dataForm.fillSpaceNum,
              goodsIds: ids.length > 0 ? ids.join(',') : '',
              goods: this.dataListSelections,
              companyId: this.dataForm.companyId,
              orgPeriodFreezerId: this.dataForm.orgPeriodFreezerId,
              orgPeriodDetailId: this.dataForm.orgPeriodDetailId,
              orgPeriodCompanyId:
                this.companyList.length > 0
                  ? this.companyList.find((i) => {
                      return i.id === this.dataForm.companyId;
                    }).orgPeriodCompanyId
                  : null,
              pickupDate: moment(this.dataForm.date).format('YYYY-MM-DD'),
            },
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
