import { render, staticRenderFns } from "./freezer-move.vue?vue&type=template&id=2113ee59&scoped=true&"
import script from "./freezer-move.vue?vue&type=script&lang=js&"
export * from "./freezer-move.vue?vue&type=script&lang=js&"
import style0 from "./freezer-move.vue?vue&type=style&index=0&id=2113ee59&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2113ee59",
  null
  
)

export default component.exports