<template>
  <el-drawer
    :title="dataForm.freezerName + '-仓位管理'"
    :visible.sync="visible"
    direction="rtl"
    append-to-body
    destroy-on-close
    :close-on-press-escape="false"
    :wrapperClosable="false"
    class="categoryDrwer"
  >
    <div class="drawContainer">
      <div class="drawContainLeft">
        <div class="leftTil">时段</div>
        <div class="leftMainBox">
          <div class="leftList">
            <div class="leftName">名称</div>
            <div class="leftValue">{{ dataForm.periodWithTimeName }}</div>
            <div class="leftName">属性</div>
            <div class="leftValue">{{ isGroup ? '团餐柜' : '普通柜' }}</div>
            <div class="leftName">仓位</div>
            <div class="leftValue">{{ spaceNum }}</div>
          </div>
          <div class="leftList" v-if="isGroup">
            <div class="leftName">总仓位：{{ companySpace.spaceNum }}</div>
            <div class="leftName">已分配仓位：{{ companySpace.usedSpace }}</div>
            <div class="leftName">
              剩余仓位：{{ companySpace.remainingSpace }}
            </div>
            <el-popover placement="right" width="600" trigger="click">
              <el-table :data="companySpace.companySpace">
                <el-table-column prop="companyName" label="公司名称" />
                <el-table-column prop="space" label="分配仓位" />
                <el-table-column prop="weeks" label="星期" />
                <el-table-column prop="startDate" label="开始日期" />
                <el-table-column prop="endDate" label="结束日期" />
              </el-table>
              <el-button slot="reference">分配详情</el-button>
            </el-popover>
          </div>
          <div class="leftList">
            <div class="leftName">取餐日期</div>
            <div class="leftValue">
              <el-calendar v-model="chooseDate">
                <template slot="dateCell" slot-scope="{ date, data }">
                  <p
                    :class="data.isSelected ? 'timeSelect' : ''"
                    @click="getDay(data)"
                    v-if="!isCheckWeek(date)"
                  >
                    {{ data.day.substr(8, 2) }}
                  </p>
                  <p class="unClick" @click="falseClick" v-else>
                    {{ data.day.substr(8, 2) }}
                  </p>
                </template>
              </el-calendar>
            </div>
          </div>
        </div>
      </div>
      <div class="drawContainRight">
        <div class="rightTil">智能取餐柜仓位</div>
        <div class="rightCon">
          <div class="leftTable">
            <div class="searchList">
              <el-button
                @click="
                  $dialog(
                    'allocation',
                    isGroup,
                    dataForm.orgPeriodFreezerId,
                    dataForm.orgPeriodDetailId,
                    chooseDate,
                  )
                "
                size="mini"
                :disabled="!isFalseClick"
                >自动分配</el-button
              >
            </div>
            <div class="autoBox">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane
                  :label="
                    item.index === 0
                      ? '1号架'
                      : item.index === 1
                      ? '2号架'
                      : '3号架'
                  "
                  :name="
                    item.index === 0
                      ? '1号架'
                      : item.index === 1
                      ? '2号架'
                      : '3号架'
                  "
                  v-for="item in tableData"
                  :key="item.index"
                >
                  <!-- 一个货架 -->
                  <div
                    class="inlineBox"
                    v-for="(sitem, sindex) in item.shelves"
                    :key="sitem.index"
                  >
                    <!-- 货架标题 -->
                    <div class="boxTil">
                      {{
                        sindex === 0
                          ? '上层货架'
                          : sindex === 1
                          ? '中层货架'
                          : '下层货架'
                      }}
                    </div>
                    <!-- 货架每行 -->
                    <div
                      class="boxList"
                      v-for="(kitem, kindex) in sitem.rows"
                      :key="kindex"
                    >
                      <!-- 货架每列 -->
                      <div
                        class="boxItem"
                        v-for="(litem, lindex) in kitem.cols"
                        :key="lindex"
                        @click="checkCode(litem)"
                        :class="[
                          { yellowCode: litem.state === 1 },
                          { redCode: litem.state === 2 },
                        ]"
                      >
                        <el-badge
                          :value="litem.goodsNum"
                          class="goodsNum"
                          v-if="litem.goodsNum > 0"
                        >
                          {{ litem.spaceCode }}
                        </el-badge>
                        <p v-else>{{ litem.spaceCode }}</p>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="rightTable">
            <div class="searchList">
              <el-form :inline="true" :model="searchForm" class="bysearchForm">
                <el-form-item class="typeInput">
                  <el-input
                    clearable
                    size="mini"
                    v-model="searchForm.code"
                    placeholder="订单编号"
                  />
                </el-form-item>
                <el-form-item class="typeInput">
                  <el-input
                    clearable
                    size="mini"
                    @clear="resetCode"
                    v-model="searchForm.sn"
                    placeholder="仓位码"
                  />
                </el-form-item>
                <el-form-item class="typeInput">
                  <el-button @click="getDataList" size="mini">查询</el-button>
                  <el-button @click="reset" size="mini">重置</el-button>
                </el-form-item>
              </el-form>
            </div>
            <el-table
              :data="dataList"
              border
              height="calc(100vh - 250px)"
              style="width: 100%"
            >
              <el-table-column
                prop="orderId"
                header-align="center"
                align="center"
                label="订单编号"
              />
              <el-table-column
                prop="spaceCode"
                header-align="center"
                align="center"
                label="仓位码"
              />
              <el-table-column
                prop="name"
                header-align="center"
                align="center"
                label="商品名称"
              />
              <el-table-column
                prop="categoryName"
                header-align="center"
                align="center"
                label="商品分类"
              />
              <el-table-column
                width="60"
                prop="price"
                header-align="center"
                align="center"
                label="价格"
              />
              <el-table-column
                header-align="center"
                align="center"
                label="取餐时间"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.pickupTime">{{
                    scope.row.pickupTime
                  }}</span>
                  <span v-else style="color: #999">未取餐</span>
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                align="center"
                label="回收时间"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.withdrawnAt">{{
                    scope.row.withdrawnAt.substr(11, 5)
                  }}</span>
                  <span v-else style="color: #999">未回收</span>
                </template>
              </el-table-column>
              <el-table-column
                header-align="center"
                align="center"
                fixed="right"
                width="140"
                label="操作"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="
                      $dialog('movecate', tableData, allDataList, scope.row.id)
                    "
                    :disabled="!!scope.row.withdrawnAt"
                  >
                    移仓
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click="withdrawHandle(scope.row.id)"
                    :disabled="!!scope.row.withdrawnAt"
                  >
                    回收
                  </el-button>
                  <el-button
                    type="text"
                    size="small"
                    @click="deleteHandle(scope.row.id)"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <div class="btnGroup">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="visible = false">确认</el-button>
    </div>
    <!-- 分配仓位 -->
    <allocation-num
      v-if="allocationVisible"
      ref="allocation"
      @refreshDataList="getDataList"
    />
    <!-- 移仓 -->
    <movecate
      v-if="movecateVisible"
      ref="movecate"
      @refreshDataList="getDataList"
    />
  </el-drawer>
</template>

<script>
import moment from 'moment';
import { normal } from '@/mixins';
import allocationNum from './freezer-goods-add-or-update';
import movecate from './freezer-move';
export default {
  mixins: [normal],
  data() {
    return {
      visible: false,
      isFalseClick: true,
      activeName: '1号架', // 智能取餐柜货架1号架 2号架 以此类推
      searchForm: {
        // 右侧搜索栏
        sn: null,
        code: null,
      },
      chooseDate: null, // 默认拿当天日期，用于通过日期查询列表的数据
      companySpace: {
        spaceNum: '',
        usedSpace: '',
        remainingSpace: '',
        companySpace: [],
      },
      isGroup: false, // 是否团餐柜
      spaceNum: '',
      week: [],
      dataForm: {
        // 外部传入的数据，用于保存参数
        freezerId: '',
        periodId: '',
        orgPeriodFreezerId: '',
        address: '',
        createdAt: '',
        cutOffTime: null,
        cutOffTime2: '',
        cutOffTime2show: null,
        endDate: '',
        endTime: '',
        freezerName: '',
        freezerSn: '',
        id: 0,
        imageUrl: '',
        isUnified: false,
        latitude: 0,
        longitude: 0,
        periodWithTimeName: '',
        shelfCol: 0,
        shelfRow: 0,
        shelfStack: 0,
        specName: '',
        startDate: '',
        startTime: '',
        weeks: '1',
      },
      tableData: [], // 智能取餐柜的层数列
      allDataList: [], // 用于存储数据，防止数据丢失
      dataList: [], // 右侧表格显示的内容页，默认和上面的存储数据一样
      allocationVisible: false, // 分配仓位弹窗
      movecateVisible: false, // 移仓弹窗
    };
  },
  components: {
    allocationNum,
    movecate,
  },
  methods: {
    init(row) {
      this.dataForm = JSON.parse(JSON.stringify(row));
      this.dataForm.orgPeriodFreezerId = this.dataForm.id;
      // this.dataForm.cutOffTime2show = this.dataForm.cutOffTime2
      //   ? '前一天' + this.dataForm.cutOffTime2.substr(0, 5)
      //   : '无预售';
      this.week = this.dataForm.weeks.split(',');
      this.visible = true;
      this.chooseDate = moment(new Date()).format('YYYY-MM-DD');
      this.$nextTick(() => {
        this.getDataList();
      });
    },
    falseClick() {
      this.isFalseClick = false;
    },
    isCheckWeek(date) {
      return !this.week.includes(date.getDay().toString());
    },
    getDataList() {
      this.$http({
        url: '/tc/orgPeriodFreezer/space-info',
        method: 'get',
        params: {
          orgPeriodFreezerId: this.dataForm.orgPeriodFreezerId,
          pickupDate: moment(this.chooseDate).format('YYYY-MM-DD'),
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataForm.orgPeriodDetailId = data.data.orgPeriodDetailId;
          this.tableData = data.data.spaces;
          this.isGroup = data.data.isGroup;
          this.spaceNum = data.data.spaceNum;
          if (this.isGroup) {
            this.companySpace = data.data.space;
          }
          let getTableData = JSON.parse(
            JSON.stringify(data.data.orderDetailList),
          );
          if (getTableData && getTableData.length > 0) {
            getTableData.forEach((i) => {
              if (i.orderId) {
                i.newOrderId = this.checkZero(i.orderId, 12);
              } else {
                i.newOrderId = '-';
              }
            });
          }
          this.allDataList = getTableData;
          this.dataList = getTableData;
        } else {
          this.tableData = [];
          this.allDataList = [];
          this.dataList = [];
        }
      });
    },
    // 补0
    checkZero(id, length) {
      return (Array(length).join('0') + id).slice(-length);
    },
    // 货架切换
    handleClick(tab) {
      this.activeName = tab.name;
    },
    // 点击日期，触发查询数据
    getDay(row) {
      this.chooseDate = row.day;
      row.isSelected = true;
      this.isFalseClick = true;
      this.getDataList();
    },
    // 重置查询的货位商品信息
    resetCode() {
      this.dataList = this.allDataList;
    },
    // 点击触发货架仓位内容查询
    checkCode(row) {
      this.searchForm.sn = row.spaceCode;
      if (this.allDataList.length > 0) {
        this.dataList = this.allDataList.filter(
          (i) => i.spaceCode === row.spaceCode,
        );
      } else {
        this.dataList = this.allDataList;
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/mall/feedback/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
    // 删除仓位码商品
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.serialListSelections.map((item) => {
            return item.id;
          });
      this.$confirm('此操作将删除该仓位码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: `/tc/orderDetail/delete`,
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '删除成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 回收仓位码商品
    withdrawHandle(id) {
      this.$confirm('此操作将删除该仓位码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: `/tc/orderDetail/withdraw`,
            method: 'post',
            data: {
              ids: id,
            },
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '删除成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 重置
    reset() {
      this.searchForm.sn = null;
      this.searchForm.code = null;
      this.dataList = this.allDataList;
    },
  },
};
</script>
<style lang="scss">
.leftValue {
  .el-calendar {
    .el-calendar__body {
      .el-calendar-table {
        .current {
          .el-calendar-day {
            padding: 0;
            p {
              padding: 8px;
            }
          }
        }
      }
    }
  }
}
.unClick {
  cursor: not-allowed;
}
</style>
